import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ReportService {
    constructor(private httpClient: HttpClient) { }

    getReportList(assessment_id: number): Observable<any> {
        let params = new HttpParams()
        let queryParams = `?assessment_id=${assessment_id}`
        return this.httpClient.get(
            `${environment.apiUrl}/report${queryParams}`, { params: params }
        );
    }

    createReport(assessment: any, report_num: number): Observable<any> {
        let data = {
            assessment_id: assessment.assessment_id,
            report_status_id: 0, // In Progress
            report_num: report_num,
        };
        let params = new HttpParams()
        return this.httpClient.post(
            `${environment.apiUrl}/report`, data, { params: params }
        );
    }

    updateReport(data: {
        report_id: number,
        supports?: any,
        // Many more options to be added
    }): Observable<any> {
        let params = new HttpParams()
        return this.httpClient.put(
            `${environment.apiUrl}/report`, data, { params: params }
        );
    }

    /**
   * Sends request for support for report, updates report with data
   *
   * @param data body of request
   * @returns Promise indicating success/failure
   */
    requestSupport(data: {
        report_id: any,
        support_needed_type?: string,
        support_skills?: string,
        support_details?: string,
    }): Observable<any> {
        let body = {
            report_id: data.report_id,
            supports: [{
                support_type : data.support_needed_type,
                support_skills: data.support_skills,
                additional_details : data.support_details,
                support_completed: false,
            }],
        }
        let params = new HttpParams()
        return this.httpClient.put(
            `${environment.apiUrl}/report`, body, { params: params }
        );
    }
}