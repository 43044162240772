    <table mat-table [dataSource]="reports" class="report-table">
        <ng-container matColumnDef="filler">
            <th mat-header-cell *matHeaderCellDef class="filler-column" style="width: 48px;"></th>
            <td mat-cell *matCellDef="let report" class="filler-column"></td>
        </ng-container>
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>2500-8 Reports</th>
            <td mat-cell *matCellDef="let report">{{assessment.baer_name}}({{report.report_num}})</td>
        </ng-container>
        <ng-container matColumnDef="reportId">
            <th mat-header-cell *matHeaderCellDef>Report ID</th>
            <td mat-cell *matCellDef="let report" class="prewrap">{{report.report_id}}</td>
        </ng-container>
        <ng-container matColumnDef="reportStatus">
            <th mat-header-cell *matHeaderCellDef>Report Status</th>
            <td mat-cell *matCellDef="let report">
                <mat-chip *ngIf="report.report_status_id != null" [ngClass]="'chip-r-'+report.report_status_id">
                    {{report.report_status_id | reportStatusBadge}}
                </mat-chip>
                <span *ngIf="report.report_status_id == null">--</span>
            </td>
        </ng-container>
        <ng-container matColumnDef="baerTeam">
            <th mat-header-cell *matHeaderCellDef>BAER Team Members</th>
            <td mat-cell *matCellDef="let report" class="prewrap">
                <mat-chip *ngIf="report.users.length != 0; else usersDef" style="margin-left: 5px;" [matTooltip]='report.tooltip'
                    matTooltipClass="tooltipClass">
                    <fa-icon class="fa-sm" [icon]="faUsers"></fa-icon> {{report.users.length}}+
                </mat-chip>
                <ng-template #usersDef>--</ng-template>
                <mat-chip class="chip-1" *ngIf="report.support_tooltip"
                    [matTooltip]='report.support_tooltip'
                    matTooltipClass="tooltipClass"
                    style="margin-left: 10px"
                ><fa-icon class="fa-custom" [icon]="faWarning"></fa-icon>Interregional Support</mat-chip> 
            </td>
        </ng-container>
        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef style="width: 270px;">
                <div class="info-containment-div"></div>
            </th>
            <td mat-cell *matCellDef="let report" style="width: 270px;">
                <!-- Only show edit report if In-Progress or Ready To Submit -->
                <div class="action-div" *ngIf="isEditable && [0, 1].includes(report.report_status_id)">
                    <button class="first-action">
                        <fa-icon class="fa-sm" [icon]="faEdit"></fa-icon>Edit Report
                    </button>
                    <button class="menu-btn" [matMenuTriggerFor]="menu" aria-label="Individual report action list"
                        #t="matMenuTrigger" (click)="stopPropagation($event)" class="{{t.menuOpen ? 'menuOpen' : ''}}">
                        @if (t.menuOpen) {
                        <mat-icon>keyboard_arrow_up</mat-icon>
                        } @else {
                        <mat-icon>keyboard_arrow_down</mat-icon>
                        }
                    </button>
                    <mat-menu #menu="matMenu" class="action-menu" yPosition="below">
                        <button mat-menu-item (click)="requestSupport(report); stopPropagation($event)">
                            <mat-icon>support</mat-icon>
                            <span>Interregional Support</span>
                        </button>
                    </mat-menu>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="6">
                <div class="no-data">
                    <p>No reports have been created</p>
                </div>
            </td>
        </tr>
    </table>