<main id="main-content" class="main-content">
    <div style="display: flex;">
        <mat-form-field appearance="outline" style="margin-left: 0;">
            <mat-label>Report Name</mat-label>
            @if (reports.length >= 1) {
            <mat-select [(ngModel)]="selectedReport" (selectionChange)="reportChange($event)">
                @for (report of reports; track report) {
                <mat-option
                    [value]="report.report_num">{{report.assessment.baer_name}}({{report.report_num}})</mat-option>
                }
            </mat-select>
            } @else {
            <mat-select [(ngModel)]="selectedReport" [disabled]="true">
                <mat-option [value]=0>No Reports Available</mat-option>
            </mat-select>
            }
        </mat-form-field>
        <div class="date-div">
            <span style="font-weight: bold;">Tentative Start Date</span>
            @if(selectedReportInfo?.assessment.tentative_start_week) {
            <span>{{selectedReportInfo?.assessment.tentative_start_week | date:"MM/dd/YYYY"}}</span>
            } @else {
            <span>--</span>
            }
        </div>
        <div class="date-div">
            <span style="font-weight: bold;">Close-out Date</span>
            @if(selectedReportInfo?.assessment.close_out_date) {
            <span>{{selectedReportInfo?.assessment.close_out_date | date:"MM/dd/YYYY"}}</span>
            } @else {
            <span>--</span>
            }
        </div>
        <div class="button-row" *ngIf="reports.length >= 1 && isEditable">
            <button class="usa-button usa-button--secondary medium-length" (click)="ngOnInit()">
                <mat-icon>file_download</mat-icon>Download Roster
            </button> <button class="usa-button usa-button--secondary large-length" (click)="requestSupport()">
                <mat-icon>support</mat-icon>Request Interregional Support
            </button>
            <button class="usa-button medium-length" (click)="addTeamMember()">
                <mat-icon>add</mat-icon>Add Team Member
            </button>
        </div>
    </div>
    <div class="support-warning" *ngIf="selectedReportInfo?.support">
        <fa-icon [icon]="faWarning" class="faWarning" aria-label="Warning"></fa-icon>
        <div class="support-text">
            <span class="support-title">
                Interregional Support Request
            </span>
            <br>
            <span class="support-description">
              <b>Type of Support:</b> {{ selectedReportInfo.support.support_type }}
              <div *ngIf="selectedReportInfo.support.support_skills">
                <b>Skills:</b> {{ selectedReportInfo.support.support_skills || "--" }}
              </div>
              <br *ngIf="!selectedReportInfo.support.support_skills">
              <b>Additional Details:</b> {{ selectedReportInfo.support.additional_details || "--" }}
            </span>
        </div>
    </div>
    <div class="support-warning support-complete" *ngIf="supportCompleted">
        <i class="material-icons check-icon faWarning" aria-label="Check">check_circle</i>
        <div class="support-text">
            <span class="support-title">
                Interregional Support Request Completed
            </span>
            <br>
            <span class="support-description">
              <b>Type of Support:</b> {{ supportCompleted.support_type }}
              <div *ngIf="supportCompleted.support_skills">
                <b>Skills:</b> {{ supportCompleted.support_skills || "--" }}
              </div>
              <br *ngIf="!supportCompleted.support_skills">
              <b>Additional Details:</b> {{ supportCompleted.additional_details || "--" }}
            </span>
            <br>
            <span>
                <i>Request completed by {{ user.first_name +" "+ user.last_name }} on {{ completionDate }}</i>
            </span>
        </div>
        <button class="usa-button usa-button--secondary medium-length dismiss-button" (click)="supportCompleted=false;">
            Dismiss
        </button>
    </div>
    <h1>Team Roster</h1>
    <h3 *ngIf="reports.length >= 1">Team Leads and Assistants</h3>
    <p *ngIf="reports.length >= 1">{{leads.data.length}} team lead(s)</p>
    <table #leadTable *ngIf="reports.length >= 1" mat-table [dataSource]="leads" class="center" matSort matSortDisableClear
        aria-hidden="false">
        <ng-container matColumnDef="teamLeadName">
            <th mat-header-cell *matHeaderCellDef class="select-header">Team Lead Name</th>
            <td class="user-cell" mat-cell *matCellDef="let user">
                <div>
                    <div class="user-cell-name">
                        <div class="user-icon">
                            <p>{{user.user.first_name[0]}}</p>
                        </div>
                        <a [routerLink]="['/user']" [queryParams]="{user_id: user?.user?.user_id}"
                            class="user-name">{{user.user.first_name + " " + user.user.last_name}}</a>
                    </div>
                    <div class="user-cell-details">
                        <div class="details-number"><mat-icon>phone</mat-icon> {{user.user.phone_num | phone}}</div>
                        <div class="details-email"><mat-icon>email</mat-icon>{{user.user.email}}</div>
                    </div>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="role">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 150px;">Role</th>

            <td class="user-cell" mat-cell *matCellDef="let user">
                <mat-chip>{{user.role.role}}</mat-chip>
            </td>
        </ng-container>
        <ng-container matColumnDef="skills">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Skill(s)</th>
            <td mat-cell *matCellDef="let user">
                @for (skill of user.skills; track skill) {
                <mat-chip>{{skill.other_skill || skill.skill.text}}</mat-chip>
                }
            </td>
        </ng-container>
        <ng-container matColumnDef="demobilize">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 230px;">Demobilize Team Member</th>
            <td mat-cell *matCellDef="let user">
                <div class="usa-checkbox">
                    <input class="usa-checkbox__input" id="{{user.user_id}}" type="checkbox" [(checked)]="user.demobilize"
                        (change)="demobilizeCheck(user.user_id, user.demobilize)" aria-label="Toggle Select">
                    <label class="usa-checkbox__label usa-checkbox__label2" for="{{user.user_id}}">Demobilize</label>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef style="width: 130px;">Actions</th>
            <td mat-cell *matCellDef="let user" class="action-cell">
                <div>
                    <button mat-icon-button aria-label="edit" (click)="editUser(user)" style="font-size: 12px;"
                        matTooltip='Edit' matTooltipClass="tooltipClass" class="btn_edit">
                        <fa-icon class="fa-sm" [icon]="faEdit" style="color: #216E1F;"></fa-icon></button>
                    <button mat-icon-button aria-label="remove" (click)="removeUser(user.user_id)" style="font-size: 12px;"
                        matTooltip='Remove user from roster' matTooltipClass="tooltipClass">
                        <fa-icon class="fa-sm" [icon]="faUserSlash" style="color: #757575;"></fa-icon></button>
                </div>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="leadDisplayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: leadDisplayedColumns;"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="6">
                <div class="no-data">
                    <div class="no-data-icon">
                        <fa-icon [icon]="faUsers" class="no-data-icon"></fa-icon>
                    </div>
                    <div class="no-data-title">No Team Members</div>
                </div>
            </td>
        </tr>
    </table>
    <h3 *ngIf="reports.length >= 1">Team Members and Trainees</h3>
    <p *ngIf="reports.length >= 1">{{members.data.length}} team member(s)</p>
    <table *ngIf="reports.length >= 1" mat-table [dataSource]="members" class="center" matSort matSortDisableClear
        aria-hidden="false">
        <ng-container matColumnDef="teamMemberName">
            <th mat-header-cell *matHeaderCellDef class="select-header">Team Member Name</th>
            <td class="user-cell" mat-cell *matCellDef="let user">
                <div>
                    <div class="user-cell-name">
                        <div class="user-icon">
                            <p>{{user.user.first_name[0]}}</p>
                        </div>
                        <a [routerLink]="['/user']" [queryParams]="{user_id: user?.user?.user_id}"
                            class="user-name">{{user.user.first_name + " " + user.user.last_name}}</a>
                    </div>
                    <div class="user-cell-details">
                        <div class="details-number"><mat-icon>phone</mat-icon> {{user.user.phone_num | phone}}</div>
                        <div class="details-email"><mat-icon>email</mat-icon>{{user.user.email}}</div>
                    </div>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="permission">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Permission</th>

            <td class="user-cell" mat-cell *matCellDef="let user">
                @if(user.editor) {
                <mat-chip>Editor</mat-chip>
                } @else {
                <mat-chip>Viewer</mat-chip>
                }
            </td>
        </ng-container>
        <ng-container matColumnDef="role">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 150px;">Role</th>

            <td class="user-cell" mat-cell *matCellDef="let user">
                <mat-chip>{{user.role.role}}</mat-chip>
            </td>
        </ng-container>
        <ng-container matColumnDef="skills">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Skill(s)</th>
            <td mat-cell *matCellDef="let user">
                @for (skill of user.skills; track skill) {
                <mat-chip>{{skill.other_skill || skill.skill.text}}</mat-chip>
                }
            </td>
        </ng-container>
        <ng-container matColumnDef="demobilize">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 230px;">Demobilize Team Member</th>
            <td mat-cell *matCellDef="let user">
                <div class="usa-checkbox">
                    <input class="usa-checkbox__input" id="{{user.user_id}}" type="checkbox"
                        [ngModel]="user.demobilize" value="{{user.user_id}}"
                        (change)="demobilizeCheck(user.user_id, user.demobilize)" aria-label="Toggle Select">
                    <label class="usa-checkbox__label usa-checkbox__label2" for="{{user.user_id}}">Demobilize</label>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef style="width: 130px;">Actions</th>
            <td mat-cell *matCellDef="let user" class="action-cell">
                <div>
                    <button mat-icon-button aria-label="edit" (click)="editUser(user)" style="font-size: 12px;"
                        matTooltip='Edit' matTooltipClass="tooltipClass" class="btn_edit">
                        <fa-icon class="fa-sm" [icon]="faEdit" style="color: #216E1F;"></fa-icon></button>
                    <button mat-icon-button aria-label="remove" (click)="removeUser(user.user_id)" style="font-size: 12px;"
                        matTooltip='Remove user from roster' matTooltipClass="tooltipClass">
                        <fa-icon class="fa-sm" [icon]="faUserSlash" style="color: #757575;"></fa-icon></button>
                </div>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="memberDisplayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: memberDisplayedColumns;"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="6">
                <div class="no-data">
                    <div class="no-data-icon">
                        <fa-icon [icon]="faUsers" class="no-data-icon"></fa-icon>
                    </div>
                    <div class="no-data-title">No Team Members</div>
                </div>
            </td>
        </tr>
    </table>

    <div class="no-data" *ngIf="reports.length == 0">
        <div class="no-data-icon">
            <fa-icon [icon]="faUsers" class="no-data-icon"></fa-icon>
        </div>
        <div class="no-data-page"><span style="font-weight: bold;">Create an Initial Report</span> first from the <span
                style="font-weight: bold;">2500-8 Reports</span> tab
            to be able to add team member(s) to your roster</div>
    </div>
</main>