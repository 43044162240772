import { Component, EventEmitter, Input, OnInit, Output, input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog'
import { MatTableDataSource } from '@angular/material/table';
import { DialogService } from 'src/app/services/dialog.service';
import { faEdit, faUserSlash, faUsers, faWarning } from '@fortawesome/free-solid-svg-icons';
import { Title } from '@angular/platform-browser';
import { AddTeamMemberComponent } from './add-team-member/add-team-member.component';
import { ReportService } from 'src/app/services/report.service';
import { DemobilizeComponent } from './demobilize/demobilize.component';
import { UserMemberService } from 'src/app/services/user-member.service';
import { ConfirmationToastComponent } from 'src/app/components/confirmation-toast/confirmation-toast.component';
import { RemoveMemberComponent } from './remove-member/remove-member.component';
import { RequestSupportComponent } from '../../assessments/request-support/request-support.component';

@Component({
  selector: 'app-teams-tab',
  templateUrl: './teams-tab.component.html',
  styleUrls: ['./teams-tab.component.scss'],
})


export class TeamsTabComponent implements OnInit {

  faWarning = faWarning;

  faUsers = faUsers
  faUserSlash = faUserSlash
  faEdit = faEdit
  //data
  @Input() reports: any;
  @Output() reportsChange = new EventEmitter();
  @Input() assessment: any;
  @Input() user: any;
  @Input() report_num?: number;

  //access
  @Input() isTeamLead: boolean = false;
  @Input() isWO: boolean = false;
  @Input() isEditable = true;

  usersSource !: any;
  selectedReport: any;
  selectedReportInfo: any;
  leads = new MatTableDataSource<any>();
  members = new MatTableDataSource<any>();
  leadDisplayedColumns: string[] = [
    'teamLeadName',
    'role',
    'skills'
  ];
  memberDisplayedColumns: string[] = [
    'teamMemberName',
    'permission',
    'role',
    'skills'
  ];

  supportCompleted: any = null;
  completionDate: String = new Date().toLocaleString("en-US")//"";

  constructor(
    public dialog: MatDialog,
    private readonly dialogService: DialogService,
    private readonly userMemberService: UserMemberService,
    private readonly toast: ConfirmationToastComponent,
    private readonly titleService: Title,
    private readonly reportService: ReportService,
  ) {
    this.titleService.setTitle("BAER - Teams")
  }

  ngOnInit(): void {
    if (this.isEditable) {
      this.memberDisplayedColumns.push(
        'demobilize',
        'actions');
      this.leadDisplayedColumns.push(
        'demobilize',
        'actions')
    }
    this.dialogService.openSpinner();
    //get last report as default
    this.selectedReport = 0;
    for (let report of this.reports) {
      this.selectedReport = Math.max(this.selectedReport, report.report_num);
    }
    if (this.report_num) {
      this.selectedReport = Number(this.report_num)

    }
    //load report info
    if (this.selectedReport != 0)
      this.loadReportInfo();
    this.dialogService.closeSpinner();
  }

  loadReportInfo() {
    this.selectedReportInfo = this.reports.find((report: any) => report.report_num == this.selectedReport);
    this.leads.data = this.selectedReportInfo.users.filter((user: any) => user.role_id == 0 || user.role_id == 1);
    this.members.data = this.selectedReportInfo.users.filter((user: any) => user.role_id != 0 && user.role_id != 1);
    if (this.selectedReportInfo?.supports) {
      let support = this.selectedReportInfo.supports.find((support: any) => {
        return !support.support_completed 
      })
      if (support) {
        this.selectedReportInfo.support = support
      }
    }
  }

  reloadReportInfo() {
    // May need to go to the parent component and reload there instead
    this.reportService.getReportList(this.assessment.assessment_id).subscribe({
      next: (data: any) => {
        data.reports.sort((reportA: any, reportB: any) => reportA.report_num - reportB.report_num)
        this.reports = data.reports;
        this.loadReportInfo()
      }
    })
  }

  reportChange(event: any) {
    this.loadReportInfo();
  }

  demobilizeCheck(user_id: any, demobilize: boolean) {
    let user = this.selectedReportInfo.users.find((user: any) => user.user_id == user_id)

    this.dialog.open(DemobilizeComponent, {
      maxWidth: "440px",
      panelClass: ['animate__animated', 'animate__fadeInDown'],
      data: {
        user_name: user.user.first_name + ' ' + user.user.last_name,
        demobilize: !demobilize,
        report_name: this.selectedReportInfo.assessment.baer_name + '(' + this.selectedReportInfo.report_num + ')'
      },
    }).afterClosed().subscribe((result) => {
      if (result == 1) { //confirm option hit
        this.userMemberService.updateMember(user_id, this.selectedReportInfo.report_id, { 'demobilize': !demobilize }).subscribe({
          next: (response) => { //update parent report list to keep update between tab changes
            this.reports.find((report: any) => report.report_num == this.selectedReport).users.find((user: any) => user.user_id == user_id).demobilize = !demobilize;
            this.reportChange(this.reports);
            if (!demobilize)
              this.toast.openSuccessSnackBar(`${user.user.first_name + ' ' + user.user.last_name} was demobilized from team roster.`);
            else
              this.toast.openSuccessSnackBar(`${user.user.first_name + ' ' + user.user.last_name} was mobilized back into team roster.`);
          },
          error: (response) => { //reset since no changes
            this.toast.openErrorSnackBar(`There was an error demobilizing the user ${user.user.first_name + ' ' + user.user.last_name}.`, "Please try again.");
            this.leads.data = [];
            this.loadReportInfo();
          },
        })
      } else { //reset since no changes
        this.leads.data = [];
        this.loadReportInfo();
      }
    })
  }

  removeUser(user_id: any) {
    let user = this.selectedReportInfo.users.find((user: any) => user.user_id == user_id)
    this.dialog.open(RemoveMemberComponent, {
      maxWidth: "440px",
      panelClass: ['animate__animated', 'animate__fadeInDown'],
      data: {
        user_name: user.user.first_name + ' ' + user.user.last_name
      },
    }).afterClosed().subscribe((result) => {
      if (result == 1) { //confirm option hit
        this.userMemberService.removeMember(user_id, this.selectedReportInfo.report_id).subscribe({
          next: (response) => { //update parent report list to keep update between tab changes
            this.reports.find((report: any) => report.report_num == this.selectedReport).users = this.reports.find((report: any) => report.report_num == this.selectedReport).users.filter((user: any) => user.user_id != user_id);
            this.reportChange(this.reports);
            this.toast.openSuccessSnackBar(`Team member removed from ${this.selectedReportInfo.assessment.baer_name}(${this.selectedReportInfo.report_num}) roster`);
          },
          error: (response) => { //no changes
            this.toast.openErrorSnackBar(`There was an error removing the user ${user.user.first_name + ' ' + user.user.last_name}.`, "Please try again.");
          },
        })
      } else { //no changes
      }
    })
  }

  editUser(user: any) {

  }

  addTeamMember() {
    this.dialog.open(AddTeamMemberComponent, {
      width: "700px",
      panelClass: ['animate__animated', 'animate__fadeInDown'],
      data: {
        report: this.selectedReportInfo,
        isWO: this.isWO,
        user: this.user,
        assessment: this.assessment
      },
    }).afterClosed().subscribe((reload: any | boolean) => {
      this.titleService.setTitle("BAER - Teams")
      if (reload) {
        this.reloadReportInfo()
      }
      if (reload.supportCompleted) {

        this.supportCompleted = reload.supportCompleted
        this.completionDate = new Date().toLocaleString("en-US")
      }
    })
  }

  requestSupport(): void {
    this.dialog.open(RequestSupportComponent, {
      maxWidth: "900px",
      panelClass: ['animate__animated', 'animate__fadeInDown'],
      data: {
        fireRecord: this.assessment,
        report: this.selectedReportInfo,
      },
    }).afterClosed().subscribe((reload) => {
      this.titleService.setTitle("BAER - Assessments")
      if (reload) {
        this.reloadReportInfo()
      }
    })
  }
}